import { Injectable, inject } from '@angular/core';

import { BaseChangeOrder, CreateChangeOrder, EditChangeOrder, DetailedChangeOrder, PreviewChangeOrder } from '@dartsales/common/core/models/estimate/change-order/change-order';
import { EntityValidationErrors } from '@dartsales/common/core/models/errors/app-error';
import { ChangeOrderCustomValue } from '@dartsales/common/core/models/estimate/change-order/change-order-custom-value';

import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from '../mappers';
import { BaseChangeOrderDto, CreateChangeOrderDto, EditChangeOrderDto, DetailedChangeOrderDto, PreviewChangeOrderDto, EditChangeOrdersOrderDto } from '../dto/estimate/change-order/change-order.dto';
import { DateMapper } from '../date-mapper';
import { MappedValidationErrorDto } from '../dto/validation-error.dto';
import { extractErrorMessage } from '../errors/extract-error-message';
import { TermSummaryMapper } from '../estimate-services/term-summary.mapper';

import { ModulePropertiesMapper } from './modules/module-properties.mapper';
import { ModulesPropertiesListMapper } from './modules-propterties-list.mapper';
import { AmountPercentMapper } from './amount-percent.mapper';

/** Change order mapper. */
@Injectable({
  providedIn: 'root',
})
export class ChangeOrderMapper implements
  IMapperFromDto<BaseChangeOrderDto, BaseChangeOrder>,
  IMapperToDto<EditChangeOrderDto, EditChangeOrder>,
  IValidationErrorMapper<EditChangeOrderDto, EditChangeOrder> {

  private readonly dateMapper = inject(DateMapper);

  private readonly amountPercentMapper = inject(AmountPercentMapper);

  private readonly propertiesMapper = inject(ModulePropertiesMapper);

  private readonly modulesListMapper = inject(ModulesPropertiesListMapper);

  private readonly termSummaryMapper = inject(TermSummaryMapper);

  /** @inheritdoc */
  public fromDto(dto: BaseChangeOrderDto): BaseChangeOrder {
    return new BaseChangeOrder({
      id: dto.id,
      finalEstimateId: dto.finalEstimateId,
      name: dto.name ?? '',
      description: dto.description ?? '',
      status: dto.status,
      reference: dto.reference,
      clientCONumber: dto.clientCONumber ?? '',
      mechCONumber: dto.mechCONumber ?? '',
      mechCOPNumber: dto.mechCOPNumber ?? '',
      customValues: dto.customValues.map(item => new ChangeOrderCustomValue({
        id: item.id,
        value: item.value,
      })),
      grossMargin: this.amountPercentMapper.fromDto(dto.grossMargin),
      sellPrice: dto.sellPrice,
      createdAt: this.dateMapper.fromDto(dto.createdAt),
      updatedAt: this.dateMapper.fromDto(dto.updatedAt),
    });
  }

  /**
   * Map preview change order DTO to model.
   * @param dto DTO.
   */
  public fromPreviewDto(dto: PreviewChangeOrderDto): PreviewChangeOrder {
    return new PreviewChangeOrder({
      ...this.fromDto(dto),
      order: dto.order,
    });
  }

  /**
   * Map detailed change order DTO to model.
   * @param dto DTO.
   */
  public fromDetailedDto(dto: DetailedChangeOrderDto): DetailedChangeOrder {
    return new DetailedChangeOrder({
      ...this.fromDto(dto),
      modulesProperties: this.modulesListMapper.fromDto(dto),
      modulesTotals: this.propertiesMapper.fromDto(dto.totalsModule.properties),
      terms: dto.terms.map(term => this.termSummaryMapper.fromDto(term)),
      compoundEscalation: {
        percent: dto.compoundEscalationPercent,
        isEnabled: dto.isCompoundEscalationEnabled,
      },
    });
  }

  /** @inheritdoc */
  public toDto(data: EditChangeOrder): EditChangeOrderDto {
    return {
      name: data.name,
      description: data.description,
      status: data.status,
      reference: data.reference,
      clientCONumber: data.clientCONumber,
      mechCONumber: data.mechCONumber,
      mechCOPNumber: data.mechCOPNumber,
      customValues: data.customValues.map(item => ({
        id: item.id,
        value: item.value,
      })),
    };
  }

  /**
   * Map data to DTO.
   * @param data Data for creating a new change order.
   */
  public toCreateDto(data: CreateChangeOrder): CreateChangeOrderDto {
    return {
      estimateToMergeId: data.estimateId,
      name: data.name,
      description: data.description,
    };
  }

  /**
   * Map change orders DTO to order DTO.
   * @param estimate Alternate estimate.
   * @param order Order number.
   */
  public toOrderDto(estimate: PreviewChangeOrder, order: number): EditChangeOrdersOrderDto {
    return {
      id: estimate.id,
      order,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: MappedValidationErrorDto<BaseChangeOrder>,
  ): EntityValidationErrors<EditChangeOrderDto> {
    return {
      status: extractErrorMessage(errorDto?.status),
      reference: extractErrorMessage(errorDto?.reference),
      clientCONumber: extractErrorMessage(errorDto?.clientCONumber),
      mechCONumber: extractErrorMessage(errorDto?.mechCONumber),
      mechCOPNumber: extractErrorMessage(errorDto?.mechCOPNumber),
    };
  }

  /**
   * Map validation error from create DTO.
   * @param errorDto Error DTO.
   */
  public validationErrorFromCreateDto(
    errorDto: MappedValidationErrorDto<CreateChangeOrderDto>,
  ): EntityValidationErrors<CreateChangeOrder> {
    return {
      name: extractErrorMessage(errorDto?.name),
      description: extractErrorMessage(errorDto.description),
    };
  }
}
